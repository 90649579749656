<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New User
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="firstname"
            rules="required"
          >
            <b-form-group
              label="Voornaam"
              label-for="firstname"
            >
              <b-form-input
                id="firstname"
                v-model="userData.firstname"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

            <b-form-group
              label="Tussenvoegsel"
              label-for="middlename"
            >
              <b-form-input
                id="middlename"
                v-model="userData.middlename"
                trim
                placeholder="de"
              />
            </b-form-group>

          <!-- lastname -->
          <validation-provider
            #default="validationContext"
            name="lastname"
            rules="required"
          >
            <b-form-group
              label="Achternaam"
              label-for="lastname"
            >
              <b-form-input
                id="lastname"
                v-model="userData.lastname"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Doe"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="user@user.com"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <b-form-group
            label="password"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="password"
              readonly="readonly"
            />
          </b-form-group>

          <!-- organisations -->
          <validation-provider
            #default="validationContext"
            name="organisations"
            rules="required"
          >
            <b-form-group
              label="Organisatie"
              label-for="organisations"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.companyID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="organisationOptions"
                value="id"
                label="name"
                :reduce="organisation => organisation.id"
                :clearable="false"
                input-id="active"
                @change="getLocations(userData.companyID, true)"
                @input="getLocations(userData.companyID, true)"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- locations -->
          <validation-provider
              v-if="locationsOptions.length > 0"
              #default="validationContext"
              name="location"
              rules="required"
          >
            <b-form-group
                label="Locatie"
                label-for="location"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="userData.locationID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="locationsOptions"
                  value="id"
                  label="name"
                  :reduce="location => location.id"
                  :clearable="false"
                  input-id="location"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="User Role"
            rules="required"
          >
            <b-form-group
              label="Rol"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner
                  small
                  class="mr-1"
                  v-if="showAddUserSpinner"
              />
              Toevoegen
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar, BSpinner,
} from 'bootstrap-vue'
import {
  ValidationObserver, ValidationProvider,
} from 'vee-validate'
import { ref } from '@vue/composition-api'
import { alphaNum, email, required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    organisationOptions: {
      type: Array,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      locationsOptions: [],
    }
  },
  methods: {
    getLocations(id, hasLocations) {
      if (id && hasLocations) {
        store
          .dispatch('apps-users-list/fetchLocationsForCompany', id)
          .then(response => {
            this.locationsOptions = response.data.items
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ophalen locaties',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `${errorDefaultMessage.fetchDataError}`,
              },
            })
          })
      }
    },
  },
  setup(props, { emit }) {
    let showAddUserSpinner = false
    const blankUserData = {
      firstname: '',
      middlename: '',
      lastname: '',
      email: '',
      companyID: null,
      role: null,
      status: '8',
      newPassword: '',
      locationID: null,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      showAddUserSpinner = true
      userData.value.newPassword = props.password
      store.dispatch('apps-users-list/addUser', userData.value)
        .then(() => {
          showAddUserSpinner = false
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
        .catch(() => {
          showAddUserSpinner = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Aanmaken gebruiker',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${errorDefaultMessage.saveDataError}`,
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      showAddUserSpinner,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
